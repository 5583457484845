import {Logger} from "@utildata/logger";
import {useEffect, useState} from "react";
import {LoggerContext} from "./LoggerContext";
import {Application} from "@utildata/logger/dist/Enums";

export const LoggerProvider = ({ children }) => {
    const [logger, setLogger] = useState(null);
    useEffect(() => {
        const doAsync = async () => {
            const loggerClass = new Logger(Application.UAPP);
            const logger = await loggerClass.createLogger();
            setLogger(logger);
        }
        doAsync();
    },[])
    
    return(
        <LoggerContext.Provider value={logger}>
            {children}
        </LoggerContext.Provider>
    )
}