import { Config, db, getByTableName, LocalAttchments } from "../db";
import { toast } from "react-toastify";
import { Tariff_prefix } from "../Parameters";
import {
  api_host,
  api_port,
  api_protocol,
  utilflow_hasConfig,
  utilflow_host,
  utilflow_path,
  utilflow_port,
  utilflow_protocol,
} from "../Config";

import { t } from "i18next";

import devCfg from "../../configs/general/default.json";
import devFieldCfg from "../../configs/general/fieldsConfig.json";
import { string } from "prop-types";
import {Logger} from "@utildata/logger";
import {Application} from "@utildata/logger/dist/Enums";

let protocol = "https";
let host = "gis.wbv-sude-schaale.de";
let port = "8443";
let servicePath = "util-flow/service/drain";
let baseUrl = protocol + "://" + host + ":" + port + "/" + servicePath + "/";

let hasOptionalErrors = false;
let fieldConfigFile;
let defaultConfigPath;
const recipeLogoPath = `config/printLogo.png`

let attachmentsTemp = [];

export default async function SyncFlow(logger) {
  fieldConfigFile = `config/fieldsConfig.json`;
  defaultConfigPath = `config/default.json`;

  if (!navigator.onLine) {
    toast.error(t("sync.noInternet"));
    return;
  }

  try {
    //await clearCache();
    await getFieldsConfig();
    await getDefaultConfig();

    await syncBack();
    await sync();

    try {
      await Promise.all([
        getApplicationLogo(),
        getConfirmationLogo(),
        getRecipeHeaderFile(),
        sendLogs()
      ]);
    } catch {
      hasOptionalErrors = true;
    }

    if (!hasOptionalErrors) {
      toast.success(t("sync.ok"));
    } else {
      toast.warn(t("sync.okWithWarnings"));
    }
  } catch (err) {
    console.info(err)
    logger.error(err.message,err.stack);
    toast.error(t("sync.error"));
  }
  return;
}

const sendLogs = async () => {
  const loggerClass = new Logger(Application.UAPP)
  const logger =  await loggerClass.createLogger();
  await logger.Manager.trySendLogs()
}

const getDefaultConfig = async () => {
  if (!process.env.NODE_ENV || process.env.NODE_ENV === "development") {
    host = devCfg.host;
    port = devCfg.port;
    protocol = devCfg.protocol;
    servicePath = devCfg.servicePath;
    baseUrl = protocol + "://" + host + ":" + port + "/" + servicePath + "/";
    return;
  }

  try {
    const conetent = await fetch(defaultConfigPath);
    const defaultConfig = await conetent.json();

    host = defaultConfig.host;
    port = defaultConfig.port;
    protocol = defaultConfig.protocol;
    servicePath = defaultConfig.servicePath;

    baseUrl = protocol + "://" + host + ":" + port + "/" + servicePath + "/";
    console.log(baseUrl);

    //TODO
    // await Config.addOrUpdate({key: utilflow_protocol, value: defaultConfig.protocol});
    // await Config.addOrUpdate({key: utilflow_host, value: defaultConfig.host});
    // await Config.addOrUpdate({key: utilflow_port, value: defaultConfig.port});
    // await Config.addOrUpdate({key: utilflow_path, value: defaultConfig.servicePath});
    // await Config.addOrUpdate({key: utilflow_hasConfig, value: true});
  } catch (err) {
    toast.error(t("sync.cfg.fileNotFound"));
    throw new Error(err);
  }
};

const getFieldsConfig = async () => {
  if (!process.env.NODE_ENV || process.env.NODE_ENV === "development") {
    await Config.addOrUpdate({ key: "fieldsConfig", value: devFieldCfg });
    return;
  }
  try {
    const response = await fetch(fieldConfigFile);
    const fieldsConfigJson = await response.json();
    await Config.addOrUpdate({ key: "fieldsConfig", value: fieldsConfigJson });
  } catch (e) {
    throw new Error(
      "Cannot download configuration file from server. Sync not possible."
    );
  }
};

const clearCache = async () => {
  const l = console.log;
  if ("caches" in window) {
    l("CacheStorage is present for this app");
    caches.keys().then(function (cacheArray) {
      l(cacheArray);
      cacheArray.forEach(function (cache) {
        caches
          .delete(cache)
          .then((bool) => {
            l("deleted cache: " + cache);
          })
          .catch((err) => {
            l(err);
          });
      });
    });
  }
};

const getConfirmationLogo = async () => {
  try {
    const logo = await fetch(baseUrl + "confirmation-logo-file");
    if (!logo.ok) {
      throw new Error(
        "Error in inport confitmation logo. Statuscode:" + logo.status
      );
    }

    const blob = await logo.blob();
    let reader = new FileReader();
    reader.onloadend = function () {
      addConfirmationLogo(this.result);
    };
    reader.readAsDataURL(blob);
  } catch (err) {
    toast.warning(t("sync.img.confirmationFileNotFound"));
    return Promise.reject();
  }
};

const getRecipeHeaderFile = async () => {
  try {
    const file = await fetch(recipeLogoPath);
    const blob = await file.blob();
    let reader = new FileReader();
    reader.onloadend = function () {
      addRecipeHeader(this.result);
    };
    reader.readAsDataURL(blob);
  } catch (e) {
    throw new Error(
      "Cannot download recipe header file from server. Sync not possible."
    );
  }
};

const getApplicationLogo = async () => {
  try {
    const logo = await fetch(baseUrl + "application-logo-file");
    if (!logo.ok) {
      throw new Error(
        "Error in inport application logo. Statuscode:" + logo.status
      );
    }

    const blob = await logo.blob();
    let reader = new FileReader();
    reader.onloadend = function () {
      addApplicationLogo(this.result);
    };
    reader.readAsDataURL(blob);
  } catch (err) {
    toast.warning(t("sync.img.appLogoNotFound"));
    return Promise.reject();
  }
};

const addApplicationLogo = async (logoBase64) => {
  let object = {
    key: "application-logo",
    value: logoBase64,
  };

  Config.addOrUpdate(object);
};

const addConfirmationLogo = async (logoBase64) => {
  let object = {
    key: "confirmation-logo",
    value: logoBase64,
  };

  Config.addOrUpdate(object);
};

const addRecipeHeader = async (logoBase64) => {
  let object = {
    key: "recipe-header",
    value: logoBase64,
  };

  Config.addOrUpdate(object);
}

const getExtensionFromFilename = (fileName) => {
  return "." + fileName.split(".").pop();
};

const sync = async () => {
  try {
    const bundle = await fetch(baseUrl + "bundle").catch(() => {
      //const bundle = await fetch("test_error.json").catch(() => {
      return Promise.reject();
    });

    if (!bundle.ok) {
      throw new Error(
        "Response code in bundle is diffrent than 200. Abort sync"
      );
    }

    const response = await bundle.text();

    // var jsonString_1 = response.replace(/\\"/g, '"');
    var jsonObj = JSON.parse(response);

    await db.parameter.where("id").above(-1).delete();
    jsonObj.parameters.map((parameter) => {
      db.parameter.add(parameter);
    });
    console.log("Sync parameters ok");

    await db.time.where("id").above(-1).delete();
    jsonObj.times.map((time) => {
      db.time.add(time);
    });
    console.log("Sync times ok");

    await db.pitType.where("id").above(-1).delete();
    jsonObj.pitTypes.map((pitType) => {
      db.pitType.add(pitType);
    });
    console.log("Sync pit types ok");

    await db.destination.where("id").above(-1).delete();
    jsonObj.destinations.map((destination) => {
      db.destination.add(destination);
    });
    console.log("Sync destinations ok");

    await db.employee.where("id").above(-1).delete();
    jsonObj.employees.map((employee) => {
      db.employee.add(employee);
    });
    console.log("Sync employees ok");

    await db.vehicle.where("id").above(-1).delete();
    jsonObj.vehicles.map((vehicle) => {
      db.vehicle.add(vehicle);
    });
    console.log("Sync vehicles ok");

    jsonObj.distinctTaskAttachments.map(async (att) => {
      db.attachment.add(att);
    });

    //Remove all drains with "CREATED" state
    const createdDrains = await db.drain.where({ state: "CREATED" }).toArray();
    for (var dr of createdDrains) {
      await LocalAttchments.removeAllAttachmentsByForm(dr.uuid);
      await db.drain.where({ uuid: dr.uuid }).delete();
    }

    //Remove all drains with "CREATED" state
    const createdServices = await db.service
      .where({ state: "CREATED" })
      .toArray();
    for (var srv of createdServices) {
      await LocalAttchments.removeAllAttachmentsByForm(srv.uuid);
      await db.service.where({ uuid: srv.uuid }).delete();
    }

    Promise.all(
      jsonObj.drains.map(async (drain) => {
        try {
          const existingdrain = await db.drain
            .where({ uuid: drain.uuid })
            .toArray();
          if (existingdrain.length === 0) {
            db.drain.add({
              uuid: drain.uuid,
              type: drain.type,
              state: drain.state,
              reference: drain.reference,
              number: drain.number,
              scheduleFrom: drain.scheduleFrom,
              scheduleTo: drain.scheduleTo,
              info: drain.info,
              remark: drain.remark,
              date: drain.date,
              check: drain.check,
              longitude: drain.longitude,
              latitude: drain.latitude,
              longitudeRecorded: drain.longitudeRecorded,
              latitudeRecorded: drain.latitudeRecorded,
              emailRecipient: drain.emailRecipient,
              emailDelivered: drain.emailRecipient,
              emailStatus: drain.emailStatus,
              pitNumber: drain.pit?.number,
              length: drain.pit?.length,
              tonnage: drain.pit?.tonnage,
              width: drain.pit?.width,
              pitStreet: drain.pit?.address?.street,
              pitStreetNumber: drain.pit?.address?.streetNumber,
              pitPostCode: drain.pit?.address?.postCode,
              pitCity: drain.pit?.address?.city,
              pin: drain.client?.pin,
              salutation: drain.client?.salutation,
              forname: drain.client?.forename,
              name: drain.client?.name,
              clientStreet: drain.client?.address?.street,
              clientStreetNumber: drain.client?.address?.streetNumber,
              clientPostCode: drain.client?.address?.postcode,
              clientCity: drain.client?.address?.city,
              kind: drain.kind,
              option: drain.option,
              latest: drain.latest,
              ordered: drain.ordered,
              charged: drain.charged,
              ok: drain.ok,
              location: drain.location,
              processorId: drain.processor,
              executorId: drain.executorId,
              employeeId: drain.employee,
              pitTypeId: drain.pit.type,
              serviceId: drain.service,
              destinationId: drain.destination,
              customerPresent: false,
            });
          }

          if (
            drain.service !== null &&
            (await db.service.where({ uuid: drain.service.uuid }).toArray()
              .length) == 0
          ) {
            db.service.add({
              uuid: drain.service?.uuid,
              type: drain.service?.type,
              state: drain.service?.state,
              reference: drain.service?.reference,
              number: drain.service?.number,
              scheduleFrom: drain.service?.scheduleFrom,
              scheduleTo: drain.service?.scheduleTo,
              info: drain.service?.info,
              remark: drain.service?.remark,
              date: drain.service?.date,
              check: drain.service?.check,
              longitude: drain.service?.longitude,
              latitude: drain.service?.latitude,
              latitudeRecorded: drain.service?.latitudeRecorded,
              longitudeRecorded: drain.service?.longitudeRecorded,
              emailRecipient: drain.service?.emailRecipient,
              emailStatus: drain.service?.emailStatus,
              pitNumber: drain.service?.pit?.number,
              length: drain.service?.pit?.length,
              tonnage: drain.service?.pit?.tonnage,
              width: drain.service?.pit?.width,
              pitStreet: drain.service?.pit?.address?.street,
              pitStreetNumber: drain.service?.pit?.address?.streetNumber,
              pitPostCode: drain.service?.pit?.address?.postCode,
              pitCity: drain.service?.pit?.address?.city,
              pin: drain.service?.client?.pin,
              salutation: drain.service?.client?.salutation,
              forname: drain.service?.client?.forename,
              name: drain.service?.client?.name,
              clientStreet: drain.service?.client?.street,
              clientStreetNumber: drain.service?.client?.streetNumber,
              clientPostCode: drain.service?.client?.postCode,
              clientCity: drain.service?.client?.city,
              kind: drain.service?.kinds,
              options: drain.service?.options,
              processorId: drain.service?.processor,
              executorId: drain.service?.executor,
              employeeId: drain.service?.employee,
              pitTypeId: drain.service?.pit?.type,
              drainId: drain.service?.drain,
              timeId: drain.service?.time,
              customerPresent: false,
            });

            if (
              drain.service.attachments &&
              drain.service.attachments.length > 0
            ) {
              drain.service.attachments.map(async (x) => {
                if ((await db.attachment.where("uuid").equals(x.uuid)) == 0) {
                  db.attachment.add({
                    uuid: x.uuid,
                    reference: drain.service.uuid,
                    name: x.name,
                    path: x.path,
                    type: x.type,
                  });

                  fetch(baseUrl + "attachment-file?uuid=" + x.uuid, {}).then(
                    (attachment) => {
                      attachment.blob().then((y) => {
                        db.localAttachments.add({
                          filename: x.uuid + getExtensionFromFilename(x.path),
                          blob: y,
                          formId: drain.uuid,
                          mimeType: y.type,
                          name: x.name,
                          uuid: x.uuid,
                        });
                      });
                    }
                  );
                } else {
                  console.log(
                    "Attachment with uuid: " + x.uuid + " exist. Skipping"
                  );
                }
              });
            }
          }
          if (drain.attachments && drain.attachments.length > 0) {
            drain.attachments.map(async (dx) => {
              let attachmentToSave = null;
              if(typeof dx === 'string'){
              //new code...
              const attachmentsWithGivenUuid = attachmentsTemp.filter(x => x.uuid == dx);

              console.log(dx)
              console.log()
              console.log(attachmentsTemp)
              console.log(attachmentsWithGivenUuid);
              if (attachmentsWithGivenUuid.length > 0) {
                
                attachmentToSave = attachmentsWithGivenUuid[0];
                db.attachment.add({
                  uuid: attachmentToSave.uuid,
                  reference: drain.uuid,
                  name: attachmentToSave.name,
                  path: attachmentToSave.path,
                  type: attachmentToSave.type,
                  formUuid: drain.uuid})
              }
              //.......
              }
              else{
                attachmentToSave = dx;
                attachmentsTemp.push(dx);
                db.attachment.add({
                  uuid: dx.uuid,
                  reference: drain.uuid,
                  name: dx.name,
                  path: dx.path,
                  type: dx.type,
                  formUuid: drain.uuid,
                });
              }

              fetch(baseUrl + "attachment-file?uuid=" + attachmentToSave.uuid, {}).then(
                (attachment) => {
                  attachment.blob().then((y) => {
                    db.localAttachments.add({
                      fileName: attachmentToSave.uuid + getExtensionFromFilename(attachmentToSave.path),
                      blob: y,
                      formId: drain.uuid,
                      mimeType: y.type,
                      name: attachmentToSave.name,
                      uuid: attachmentToSave.uuid,
                      deliveredFromFlow: true,
                    });
                  });
                }
              );
            });
          }
        } catch (err) {
          Promise.reject(err);
        }
      })
    );
  } catch (err) {
    console.log(err);
    throw new Error(err);
  }
};

export const syncBack = async () => {
  try {
    const drains = await db.drain.where("state").equals("COMPLETED").toArray();
    if (drains) {
      await Promise.all(
        drains.map(async (drain) => {
          try {
            const processor = await getByTableName.getRow(
              "vehicle",
              drain.processorId
            );
            delete processor.id;
            let executor;
            if (drain.processorId === drain.executorId) {
              executor = drain.executorId;
            } else {
              executor = await getByTableName.getRow(
                "vehicle",
                drain.executorId
              );
              delete executor.id;
            }
            const employee = await getByTableName.getRow(
              "employee",
              drain.employeeId
            );
            delete employee.id;

            const pitType = await getByTableName.getRow(
              "pitType",
              drain.pitTypeId
            );
            delete pitType.id;

            const destination = await getByTableName.getRow(
              "destination",
              drain.destinationId
            );
            delete destination.id;

            const model = {
              uuid: drain.uuid,
              type: drain.type,
              state: drain.state,
              reference: drain.reference,
              number: drain.number,
              scheduleFrom: drain.scheduleFrom,
              scheduleTo: drain.scheduleTo,
              info: drain.info,
              remark: drain.remark,
              date: drain.date,
              check: drain.check,
              latitude: drain.latitude,
              longitude: drain.longitude,
              latitudeRecorded: drain.latitudeRecorded,
              longitudeRecorded: drain.longitudeRecorded,
              emailRecipient: drain.emailRecipient,
              emailStatus: 1,
              kind: drain.kind
                ? "K" + drain.kind.replace(Tariff_prefix, "")
                : null,
              option: drain.option,
              latest: drain.latest,
              ordered: drain.ordered,
              charged: parseFloat(drain.charged),
              ok: drain.ok == "1" ? true : false,
              location: drain.location,
              processor,
              pit: {
                number: drain.pitNumber,
                length: parseFloat(drain.length),
                tonnage: parseFloat(drain.tonnage),
                width: parseFloat(drain.width),
                address: {
                  street: drain.pitStreet,
                  streetNumber: drain.pitStreetNumber,
                  postcode: drain.pitPostCode ?? "",
                  city: drain.pitCity,
                },
                type: pitType,
              },
              client: {
                pin: drain.pin,
                salutation: drain.salutation,
                forename: drain.forname,
                name: drain.name,
                address: {
                  street: drain.clientStreet,
                  streetNumber: drain.clientStreetNumber,
                  postcode: drain.clientPostCode ?? "",
                  city: drain.clientCity ?? "",
                },
              },
              executor,
              employee,
              attachments: [],
              service: drain.serviceId,
              destination,
            };
            try {
              await SendToUtilFlow(model);
            } catch (err) {
              throw new Error(err);
            }
          } catch (err) {
            return Promise.reject(err);
          }
        })
      );
    }

    const services = await db.service
      .where("state")
      .equals("COMPLETED")
      .toArray();
    if (services) {
      services.map(async (serv) => {
        const model = {
          uuid: serv.uuid,
          type: serv.type,
          state: serv.state,
          reference: serv.reference,
          number: serv.number,
          scheduleFrom: serv.scheduleFrom,
          scheduleTo: serv.scheduleTo,
          info: serv.info,
          remark: serv.remark,
          date: serv.date,
          check: serv.check,
          latitude: serv.latitude,
          longitude: serv.longitude,
          latitudeRecorded: serv.latitudeRecorded,
          longitudeRecorded: serv.longitudeRecorded,
          emailRecipient: serv.emailRecipient,
          emailStatus: 2,
          processor: serv.processorId,
          pit: {
            number: serv.pitNumber,
            length: serv.length,
            tonnage: serv.tonnage,
            width: serv.width,
            address: {
              street: serv.pitStreet,
              streetNumber: serv.pitStreetNumber,
              postcode: serv.pitPostCode,
              city: serv.pitCity,
            },
            type: serv.pitTypeId,
          },
          client: {
            pin: serv.pin,
            salutation: serv.salutation,
            forename: serv.forname,
            name: serv.name,
            address: {
              street: serv.clientStreet,
              streetNumber: serv.clientStreetNumber,
              postcode: serv.clientPostCode,
              city: serv.clientCity,
            },
          },
          executor: serv.executorId,
          employee: serv.employeeId,
          kinds: serv.kinds,
          time: serv.timeId,
          options: serv.options,
        };
        Promise.resolve(SendToUtilFlow(model));
      });
    }
  } catch (err) {
    throw err;
  }
};

const SendToUtilFlow = async (model) => {
  try {
    var data = model;
    const type = model.type.toLowerCase();
    const formData = new FormData();
    const attachments = await LocalAttchments.getByFormUuid(model.uuid);
    var modelAttachments = [];
    attachments.map((x) => {
      if (
        x.mimeType.includes("signature") ||
        x.mimeType.includes("picture") ||
        x.mimeType.includes("pdf+protocol") ||
        x.mimeType.includes("consent")
      ) {
        formData.append(x.uuid, x.blob, x.name);
        modelAttachments.push({
          uuid: x.uuid,
          name: x.name,
          path: x.fileName,
          type: x.mimeType,
        });
      }
    });
    data.attachments = modelAttachments;

    const blob = new Blob([[JSON.stringify(data)]], {
      type: "application/json",
    });
    formData.append(type, blob);

    try {
      const response = await fetch(baseUrl + "complete", {
        method: "POST",
        body: formData,
        headers: {},
        mode: "cors",
      });
      if (!response.ok) {
        if (response.status == 409) {
          toast.warning(t("sync.taskConflict"));
          if (type) {
            await db.drain.where("uuid").equals(data.uuid).modify({
              state: "CONFLICT",
            });
          } else {
            await db.service.where("uuid").equals(data.uuid).modify({
              state: "CONFLICT",
            });
          }
          return;
        }
        throw new Error(response.statusText);
      }
    } catch (err) {
      throw new Error(err);
    }

    if (type) {
      db.drain.where("uuid").equals(data.uuid).modify({
        state: "ARCHIVED",
      });
    } else {
      db.service.where("uuid").equals(data.uuid).modify({
        state: "ARCHIVED",
      });
    }
  } catch (err) {
    throw new Error(err);
  }
};
